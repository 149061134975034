// This is used as the API slice definition and acts as our network API interface layer.
import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { AxiosError } from 'axios';
import { API } from 'src/utils/AmplifyApiUtils';
import { PortalConfigEntity } from 'src/constants';
import { ensureUnreachable } from 'src/utils/common_utils';
import { PortalConfig } from 'src/entities/PortalConfig';
import { notify } from 'src/clients/ApiService';

export enum ApiTags {
  domains = 'Domains',
  domains_emails = 'Domain Emails',
  recommended_resources = 'Recommended Resources',
  latest_releases = 'Latest Releases',
  notifications_settings = 'Notifications Settings',
  payment_fees = 'Payment Fees',
  referrals = 'Referrals',
  importer = 'Importer',
  forms = 'Forms',
  form_responses = 'Form Responses',
  form_detail = 'Form Detail',
  templates = 'Templates',
  inbox_notifications = 'Inbox Notifications',
  users = 'Users',
  companies = 'Companies',
  // this fetches clients using rtk-query in its own endpoint.
  // Companies uses the larger get user data endpoint that should be broken up
  clientCompanies = 'Client Companies',
  fileChannels = 'File Channels',
  otherUserData = 'Other User Data',
  settings = 'Settings',
  onboarding_task_status = 'Onboarding Task Status',
  app_visibility = 'App Visibility',
  portal_config_settings = 'Portal Config Settings',
  contentfulApps = 'Contentful Apps',
  contract_templates = 'Contract templates',
  contracts = 'Contracts',
  automations = 'Automations',
  automationTemplates = 'Automation Templates',
  apiKeys = 'ApiKeys',
  webhookConfigSets = 'Webhook Config Sets',
  apps = 'Apps',
  clientUsers = 'Client Users',
  products = 'Products',
  prices = 'Prices',
  invoices = 'Invoices',
  clientInvoices = 'Client Invoices',
  appConnections = 'App Connections',
  googlemaps = 'Google Maps',
  welcomeBlurb = 'Welcome Blurb',
  analyticsClients = 'Analytics Clients',
  analyticsActiveClients = 'Analytics Active Clients',
  analyticsSubscriptions = 'Analytics Subscriptions',
  analyticsRecentlyVisited = 'Analytics Recently Visited',
  customFields = 'Custom Fields',
}

export enum ApiMethods {
  get = 'get',
  post = 'post',
  put = 'put',
  patch = 'patch',
  del = 'del',
}

export interface ApiError {
  error?: {
    data?: {
      message: string;
    };
  };
}

export const amplifyBaseQuery =
  (
    { apiName }: { apiName: string } = { apiName: 'AppAPI' },
  ): BaseQueryFn<
    {
      path: string;
      method: ApiMethods;
      options: {
        [key: string]: any;
      };
    },
    unknown,
    unknown
  > =>
  async ({ path, method, options }) => {
    try {
      switch (method) {
        case ApiMethods.get: {
          const result = await API.get(apiName, path, options);
          return { data: result };
        }
        case ApiMethods.post: {
          const result = await API.post(apiName, path, options);
          return { data: result };
        }
        case ApiMethods.del: {
          const result = await API.del(apiName, path, options);
          return { data: result };
        }
        case ApiMethods.put: {
          const result = await API.put(apiName, path, options);
          return { data: result };
        }
        case ApiMethods.patch: {
          const result = await API.patch(apiName, path, options);
          return { data: result };
        }
        default:
          return ensureUnreachable(method);
      }
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

type CloenableStatusResponse = {
  isCloneable: boolean;
};
export const appAPI = createApi({
  baseQuery: amplifyBaseQuery({ apiName: 'AppAPI' }),
  reducerPath: 'appAPI',
  tagTypes: Object.values(ApiTags),
  endpoints: (build) => ({
    getPortalConfig: build.query<PortalConfig, void>({
      query: () => ({
        path: '/entities/config',
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.portal_config_settings],
    }),
    getCloneableStatus: build.query<CloenableStatusResponse, string>({
      query: (portalId: string) => ({
        path: `/portal/${portalId}/cloneable`,
        method: ApiMethods.get,
        options: {},
      }),
    }),
    savePortalConfig: build.mutation<
      PortalConfigEntity,
      Partial<PortalConfigEntity> & Pick<PortalConfig, 'id'>
    >({
      query: (body) => ({
        path: '/entities/config',
        method: ApiMethods.post,
        options: {
          body,
        },
      }),
      invalidatesTags: [ApiTags.portal_config_settings],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Workspace settings updated.',
            dispatch,
          });
        } catch (error) {
          notify({
            status: 'error',
            errorMessage: 'Workspace settings could not be updated',
            error,
            dispatch,
          });
        }
      },
    }),
  }),
});

export const {
  useGetPortalConfigQuery,
  useSavePortalConfigMutation,
  useGetCloneableStatusQuery,
  endpoints,
} = appAPI;
