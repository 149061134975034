import React, { useContext } from 'react';
import { makeStyles, createStyles, CircularProgress } from '@material-ui/core';
import { useKBar } from 'kbar';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';
import { LatestReleaseSection } from 'src/components/HomePage/LatestReleaseSection';
import { useLatestRelease } from 'src/hooks/useLatestRelease';
import { useGetAllRecommendedResourcesQuery } from 'src/services/api';
import { ResourcesSection } from 'src/components/HomePage/ResourcesSection';
import { FlagsContext, RouteContext } from 'src/context';
import { shortcutsSidebarOpen } from 'src/store/shortcuts/slice';
import history from 'src/history';
import { toggleHelpCenterModalAction } from 'src/store/ui/actions';
import { useGetDismissedLatestReleaseQuery } from 'src/services/api/LatestReleaseApi';
import StandardPage from 'src/components/UI/StandardPage';
import { CheckIcon } from 'src/components/Icons';
import { SETTINGS_PAGE_MAX_WIDTH } from 'src/constants/uiConsts';
import { DateUtils } from 'src/utils';
import { GetCurrentUserFromState } from 'src/store/storeUtils';
import { useGetOnboardingTaskStatusQuery } from 'src/services/api/improvedOnboardingApi';
import { useCustomerSubscription } from 'src/hooks/useCustomerSubscription';
import { OnboardingTasksSection } from './OnboardingTasksSection/OnboardingTasksSection';
import { UserActionItems } from 'src/components/HomePage/UserActionItems';
import { FactOfTheDaySectionHeader } from 'src/components/HomePage/OnboardingTasksSection/SectionHeader';
import { AnalyticsSection, RecentlyVisited } from 'src/components/HomePage';
import { PERM_MOD_ANALYTICS } from 'src/constants';
import { hasPermission } from 'src/utils/UserUtils';

const useStyles = makeStyles(() =>
  createStyles({
    loadingContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '0 auto',
      height: '100%',
    },
    root: {
      paddingTop: 40,
      maxWidth: SETTINGS_PAGE_MAX_WIDTH,
      width: '100%',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      // this should add a vertical spacing between
      //  sections setup guide and latest release
      gap: 40,
      paddingBottom: '100px',
    },
  }),
);

enum ResourceAction {
  openShortcut = 'shortcuts',
  contactSupport = 'contactSupport',
  openCommandbar = 'openCommandbar',
}

export const HomePageContent = () => {
  const { query } = useContext(RouteContext);
  const { pageAction } = query;
  const currentUser = useAppSelector((state) => GetCurrentUserFromState(state));
  const currentUserPermissions = useAppSelector(
    (state) => state.user.data?.permissions,
  );

  const currentUserCreatedDate = currentUser?.createdDate;
  const userIsWeekOld =
    DateUtils.getDaysDiffOfDate(new Date(currentUserCreatedDate as string)) > 7;
  const latestReleasePost = useLatestRelease(!userIsWeekOld);
  const { allSubscriptions } = useCustomerSubscription();
  const {
    EnableNewIUHome, // this flag is used to hide/show the analytics section
  } = useContext(FlagsContext);

  const {
    data: latestReleaseDismissal,
    isLoading: isLoadingDismissedLatestRelease,
  } = useGetDismissedLatestReleaseQuery(null, { skip: !userIsWeekOld });

  const {
    isLoading: isRecommendedResourcesLoading,
    data: recommendedResources,
  } = useGetAllRecommendedResourcesQuery();

  const {
    data: onboardingTaskStatus,
    isLoading: isOnboardingTaskStatusLoading,
    error: onboardingTaskStatusError,
  } = useGetOnboardingTaskStatusQuery({ subscriptions: allSubscriptions });

  const classes = useStyles();
  const dispatch = useAppDispatch();

  /**
   * This function resets the query search params
   */
  const resetQuerySearch = () => {
    history.replace({
      search: '',
    });
  };

  const { query: commandBarQuery } = useKBar();
  const hasAnalyticsPermission = React.useMemo(
    () => hasPermission(PERM_MOD_ANALYTICS, currentUserPermissions),
    [currentUserPermissions],
  );
  const handleResourceCardActions = () => {
    switch (pageAction) {
      case ResourceAction.openShortcut:
        dispatch(shortcutsSidebarOpen(true));
        break;

      case ResourceAction.contactSupport:
        dispatch(toggleHelpCenterModalAction(true));
        break;

      case ResourceAction.openCommandbar:
        commandBarQuery.toggle();
        break;

      default:
        break;
    }
  };

  React.useEffect(() => {
    handleResourceCardActions();
    // reset pageAction query param after resource card is handled
    if (pageAction) resetQuerySearch();
  }, [pageAction]);

  // this determines whether to show the latest release card or not
  // it actually compares the latest release post id with the latest release dismissal
  // state. If the latest release id is in dismissal object do not show it.
  const showLatestReleaseSection =
    latestReleaseDismissal?.structFields.latest_release_dismissed !==
      latestReleasePost?.id && !isLoadingDismissedLatestRelease;

  const showRecommendedResourcesSection = Boolean(
    recommendedResources &&
      recommendedResources?.structFields?.length &&
      !isRecommendedResourcesLoading,
  );

  const allResourcesLoaded =
    (!isOnboardingTaskStatusLoading || onboardingTaskStatusError) &&
    !isRecommendedResourcesLoading &&
    !isLoadingDismissedLatestRelease &&
    currentUser !== undefined;

  if (!allResourcesLoaded) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <StandardPage
      title={`That's it for now`}
      topIcon={<CheckIcon />}
      desc="Check back later for more insights and advice about your business."
      showContent={Boolean(
        showLatestReleaseSection ||
          showRecommendedResourcesSection ||
          // do not show empty state when data is still loading
          isRecommendedResourcesLoading ||
          isLoadingDismissedLatestRelease ||
          // Also don't show the empty state when the new IU home is enabled
          EnableNewIUHome,
      )}
    >
      <div className={classes.root}>
        <FactOfTheDaySectionHeader />
        {onboardingTaskStatus && (
          <OnboardingTasksSection onboardingStatus={onboardingTaskStatus} />
        )}

        {EnableNewIUHome && hasAnalyticsPermission && <AnalyticsSection />}
        <UserActionItems />
        <RecentlyVisited />

        {showLatestReleaseSection && (
          <LatestReleaseSection latestReleasePost={latestReleasePost} />
        )}
        {showRecommendedResourcesSection && (
          <ResourcesSection
            resources={recommendedResources?.structFields || []}
          />
        )}
      </div>
    </StandardPage>
  );
};
