import IFlagsmith from 'flagsmith';
import { Flags } from 'flagsmith-nodejs';
import React from 'react';

// sort flags alphabetically to match flag-smith order
export const DefaultFlagsContext = {
  // These two are permanent flags, we use these to rollout flags
  // to our dogfood and launchpad segments.

  EnableAlphaApps: false,
  EnableAppDetailsPage: false,
  EnableAppsApiMutations: false,
  EnableAutomations: false,
  EnableAutomationsM3: false,
  EnableBetaApps: false,
  EnableBillingImprovements: false,
  EnableBusinessAddress: false,
  EnableInvoiceDetailsPage: false,
  EnableInvoicePaidWebhook: false,
  EnableInvoiceReceipts: false,
  EnableMobileFriendlyBillingForms: false,
  EnableNewIUHome: false,
  EnableNewIUHomeWelcomeBlurb: false,
  EnableSubscriptionPrices: false,
  EnableOneOffContract: false,
  EnableRemoveAdvancedSettings: false,
  EnableSendEmailsToUninvitedClients: false,
  EnableSubscriptionRedesign: false,
  GoogleLoginForClients: false,
  GoogleLoginForInternalUser: false,
  OnboardingDisabledReason: '',
  EnableNonUSDSubscriptions: false,
  EnableNewIUHomeSubscriptionAnalytics: false,
  EnableCompaniesCrm: false,
};

export type FlagContextType = typeof DefaultFlagsContext;

export const getServerFlagsContextValue = (flags: Flags) => ({
  EnableAppDetailsPage: flags.isFeatureEnabled('app_details_page'),
  EnableAutomations: flags.isFeatureEnabled('automations'),
  EnableBusinessAddress: flags.isFeatureEnabled('business_address'),
  EnableSendEmailsToUninvitedClients: flags.isFeatureEnabled(
    'send_emails_to_uninvited_clients',
  ),
  EnableInvoiceDetailsPage: flags.isFeatureEnabled('invoice_details_page'),
  EnableInvoicePaidWebhook: flags.isFeatureEnabled('invoice_paid_webhook'),
  EnableInvoiceReceipts: flags.isFeatureEnabled('enable_invoice_receipts'),
  GoogleLoginForInternalUser: flags.isFeatureEnabled(
    'google_login_for_internal_user',
  ),
  GoogleLoginForClients: flags.isFeatureEnabled('google_login_for_clients'),
  EnableAlphaApps: flags.isFeatureEnabled('enable_alpha_apps'),
  EnableAutomationsM3: flags.isFeatureEnabled('automations_m3'),
  EnableBetaApps: flags.isFeatureEnabled('enable_beta_apps'),
  EnableBillingImprovements: flags.isFeatureEnabled('billing_improvement_m1'),
  EnableSubscriptionPrices: flags.isFeatureEnabled('subscription_prices'),
  EnableOneOffContract: flags.isFeatureEnabled('one-off-contract'),
  OnboardingDisabledReason: flags.getFeatureValue('onboarding_disabled'),
  EnableAppsApiMutations: flags.isFeatureEnabled('enable_apps_api_mutations'),
  EnableNewIUHome: flags.isFeatureEnabled('enable_new_iu_home'),
  EnableMobileFriendlyBillingForms: flags.isFeatureEnabled(
    'mobile_friendly_billing_forms',
  ),

  EnableNonUSDSubscriptions: flags.isFeatureEnabled('non_usd_subscriptions'),
  EnableRemoveAdvancedSettings: flags.isFeatureEnabled(
    'remove_advanced_settings',
  ),
  EnableSubscriptionRedesign: flags.isFeatureEnabled('subscription_redesign'),
  EnableNewIUHomeSubscriptionAnalytics: flags.isFeatureEnabled(
    'enable_new_iu_home_subscription_analytics',
  ),
  EnableCompaniesCrm: flags.isFeatureEnabled('companies_crm'),
});

export const getClientFlagsContextValue = (flags: typeof IFlagsmith) => ({
  EnableAppDetailsPage: flags.hasFeature('app_details_page'),
  EnableAutomations: flags.hasFeature('automations'),
  EnableBusinessAddress: flags.hasFeature('business_address'),
  EnableSendEmailsToUninvitedClients: flags.hasFeature(
    'send_emails_to_uninvited_clients',
  ),
  EnableInvoiceDetailsPage: flags.hasFeature('invoice_details_page'),
  EnableInvoicePaidWebhook: flags.hasFeature('invoice_paid_webhook'),
  EnableInvoiceReceipts: flags.hasFeature('enable_invoice_receipts'),
  GoogleLoginForInternalUser: flags.hasFeature(
    'google_login_for_internal_user',
  ),
  GoogleLoginForClients: flags.hasFeature('google_login_for_clients'),
  EnableAlphaApps: flags.hasFeature('enable_alpha_apps'),
  EnableAutomationsM3: flags.hasFeature('automations_m3'),
  EnableBetaApps: flags.hasFeature('enable_beta_apps'),
  EnableBillingImprovements: flags.hasFeature('billing_improvement_m1'),
  EnableSubscriptionPrices: flags.hasFeature('subscription_prices'),
  EnableOneOffContract: flags.hasFeature('one-off-contract'),
  OnboardingDisabledReason: flags.getValue('onboarding_disabled'),
  EnableAppsApiMutations: flags.hasFeature('enable_apps_api_mutations'),
  EnableNewIUHome: flags.hasFeature('enable_new_iu_home'),
  EnableNewIUHomeWelcomeBlurb: flags.hasFeature(
    'enable_new_iu_home_welcome_blurb',
  ),
  EnableMobileFriendlyBillingForms: flags.hasFeature(
    'mobile_friendly_billing_forms',
  ),

  EnableNonUSDSubscriptions: flags.hasFeature('non_usd_subscriptions'),
  EnableRemoveAdvancedSettings: flags.hasFeature('remove_advanced_settings'),
  EnableSubscriptionRedesign: flags.hasFeature('subscription_redesign'),
  EnableNewIUHomeSubscriptionAnalytics: flags.hasFeature(
    'enable_new_iu_home_subscription_analytics',
  ),
  EnableCompaniesCrm: flags.hasFeature('companies_crm'),
});

export const FlagsContext = React.createContext(DefaultFlagsContext);
