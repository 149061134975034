import { MenuItem } from '@material-ui/core';
import * as React from 'react';
import { RegularCardBox } from 'src/components/Cards/RegularCardBox';
import { ActiveState } from 'src/components/HomePage/AnalyticsSection/ActiveState';
import { EmptyState } from 'src/components/HomePage/AnalyticsSection/EmptyState';
import { HomePageSection } from 'src/components/HomePage/HomePageSection';
import { BaseTextField } from 'src/components/TextField';
import { useAppSelector } from 'src/hooks/useStore';

interface TimeRange {
  amount: number;
  id: string;
  interval: 'month' | 'day';
  label: string;
}

const TIME_RANGES = {
  '30days': {
    id: '30days',
    label: 'Last 30 days',
    amount: 30,
    interval: 'day',
  },
  '60days': {
    id: '60days',
    label: 'Last 60 days',
    amount: 60,
    interval: 'day',
  },
  '90days': {
    id: '90days',
    label: 'Last 90 days',
    amount: 90,
    interval: 'day',
  },
  '6months': {
    id: '6months',
    label: 'Last 6 months',
    amount: 6,
    interval: 'month',
  },
  '12months': {
    id: '12months',
    label: 'Last 12 months',
    amount: 12,
    interval: 'month',
  },
} as const;

const isTimeRangeId = (id: string): id is keyof typeof TIME_RANGES =>
  id in TIME_RANGES;

const getTimeRange = (timeRange: unknown): TimeRange => {
  if (typeof timeRange !== 'string') {
    return TIME_RANGES['30days'];
  }
  return isTimeRangeId(timeRange)
    ? TIME_RANGES[timeRange]
    : TIME_RANGES['30days'];
};

const getDaysSinceMonthsAgo = (months: number): number => {
  const now = new Date();
  const pastDate = new Date(now.setMonth(now.getMonth() - months));
  const diffTime = Math.abs(Date.now() - pastDate.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

const getDays = (timeRange: TimeRange): number => {
  return timeRange.interval === 'month'
    ? getDaysSinceMonthsAgo(timeRange.amount)
    : timeRange.amount;
};

export function AnalyticsSection() {
  const clientUsers = useAppSelector((state) => state.clients.clients);
  const [timeRange, setTimeRange] = React.useState<TimeRange>(
    TIME_RANGES['30days'],
  );

  const hasAnalytics = clientUsers.length > 10;

  return (
    <HomePageSection
      title="Analytics"
      rightElement={
        <>
          {!hasAnalytics ? null : (
            <BaseTextField
              select
              variant="outlined"
              sizeVariant="medium"
              value={timeRange.id}
              onChange={(e) => setTimeRange(getTimeRange(e.target.value))}
              fullWidth
              className="max-w-32"
            >
              {Object.entries(TIME_RANGES).map(([key, value]) => (
                <MenuItem key={key} value={value.id}>
                  {value.label}
                </MenuItem>
              ))}
            </BaseTextField>
          )}
        </>
      }
    >
      <RegularCardBox className="p-6">
        {hasAnalytics ? (
          <ActiveState days={getDays(timeRange)} />
        ) : (
          <EmptyState />
        )}
      </RegularCardBox>
    </HomePageSection>
  );
}
